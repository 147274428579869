// import { initializeApp } from "firebase/app";
// import 'firebase/firestore';
// import { getFirestore } from 'firebase/firestore/lite';
// // https://firebase.google.com/docs/web/setup#available-libraries

const firebaseConfig = {
    apiKey: process.env.VUE_APP_API_KEY,
    authDomain: process.env.VUE_APP_AUTH_DOMAIN,
    projectId: process.env.VUE_APP_PROJECT_ID,
    storageBucket: process.env.VUE_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.VUE_APP_MESSAGING_SENDER_ID,
    appId: process.env.VUE_APP_APP_ID,
    measurementId: process.env.VUE_APP_MEASUREMENT_ID
};

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);
// export const db = getFirestore(app);

import firebase from 'firebase/app'
import 'firebase/firestore'

// Get a Firestore instance
const db = firebase.initializeApp(firebaseConfig).firestore();

export default db;